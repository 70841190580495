/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import html2pdf from 'html2pdf.js';
import { API_URL } from '../../../utils/Constants';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ClearSharpIcon from '@material-ui/icons/ClearSharp';

import Grid from '@material-ui/core/Grid';

import CircleDownload from '../../../assets/img/icons/circle-download.svg';

import DeleteAttachment from './DeleteAttachment';

import Pagination from '@material-ui/lab/Pagination';
import GetAppSharpIcon from '@material-ui/icons/GetAppSharp';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  dialogBackrop: {
    backgroundColor: '#595d68',
  },
  dialogContent: {
    padding: '24px 40px 30px',
    zIndex: '10001 !important',
  },
  imgDetails: {
    maxHeight: '800px',
    width: '100%',
    objectFit: 'contain',
  },
  imgTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  btnDownload: {
    cursor: 'pointer',
    margin: '8px 0 0 8px',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin:'0 10px'
  },
  iconStyle: {
    cursor: 'pointer',
    backgroundColor: '#41B67F',
    fontSize: 25,
    color: '#FFFFFF',
    borderRadius: '5px',
  },
  iconStyleReverse: {
    cursor: 'pointer',
    backgroundColor: '#FFFFFF',
    fontSize: 25,
    color: '#41B67F',
    borderRadius: '5px',
    border: '2px solid',
    borderColor: '#41B67F',
    marginRight: '10px',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '12px',
  },

}));

const Thumbnail = ({
  setOpen,
  open,
  sourceURL,
  transmittalNo,
  transmittalId,
  toDataURL,
  handlePagination,
  imageArray,
  imageIndex,
}) => {
  const classes = useStyles();
  const [openDelete, setOpenDelete] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  // const handleDownload = (url, number) => {
  //   fetch(url)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       const url = window.URL.createObjectURL(blob)
  //       const downloadLink = document.createElement('a')
  //       downloadLink.href = url
  //       downloadLink.download = `${transmittalNo}_proof_of_delivery`
  //       downloadLink.click()
  //     })
  // }

  const handleDownload = (url, number) => {

    const proof = document.getElementById('proof-image');
    let proofImage = '';
    toDataURL(url).then((dataURL) => (proofImage = dataURL));

    // 4536
    const opt = {
      filename: imageArray.length > 0 ? `${imageArray[imageIndex - 1].name}.pdf` : '',
      image: { type: 'png', quality: 1 },

      html2canvas: { dpi: 192, logging: true, scale: 5 },
      jsPDF: { unit: 'px', format: 'a4', orientation: 'landscape' },
    };

    html2pdf()
      .from(proof)
      .set(opt)
      .toPdf()
      .get('pdf')
      .then(function (pdf) {
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        const imgProps = pdf.getImageProperties(proofImage);

        const imgWidth = imgProps.width;
        const imgHeight = imgProps.height;

        const processedImgWidth = imgWidth / 2;
        const processedImgHeight = imgHeight / 2;

        const setX = pageWidth / 2 - processedImgWidth / 1.8;
        const setY = pageHeight / 2 - processedImgHeight / 1.7;

        pdf.addImage(proofImage, 'PNG', setX, setY);
      })
      .save();
  };

  // 5399
  const getImageSrc = () => {
    if (imageArray && imageArray.length > 0) {
        const currentImage = imageArray[imageIndex - 1];
        if (currentImage && currentImage.imageBase64) {
            return currentImage.imageBase64;
        } else if (imageArray[0] && imageArray[0].imageBase64) {
            return imageArray[0].imageBase64;
        }
    }
    return '';
  };

  return (
    <>
    <Dialog
      open={open}
      BackdropProps={{
        classes: {
          root: classes.dialogBackrop,
        },
      }}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick
    >
      <DialogContent fullWidth className={classes.dialogContent}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container direction="row" alignItems="center" justify="space-between">
              <div className={classes.imgTitle}>
                <div>
                  {
                    imageArray.length > 0 
                      ? `${transmittalNo}_${imageArray[imageIndex - 1] 
                          ? imageArray[imageIndex - 1].name 
                          : imageArray[0].name}` 
                      : ''
                  } 
                </div>
                {/* replaced by Material UI icon on #471 */}
                {/* <div className={classes.btnDownload}>
                  <img
                    src={CircleDownload}
                    alt="Download Icon"
                    onClick={() => handleDownload(sourceURL, transmittalNo)}
                  />
                </div> 
                */}
              </div>
              <div className={classes.iconContainer}>
                <DeleteOutlineOutlinedIcon className={classes.iconStyleReverse} onClick={() => setOpenDelete(true)} />
                <GetAppSharpIcon
                  className={classes.iconStyle}
                  onClick={() =>
                    handleDownload(
                      imageArray.length > 0 ? `${imageArray[imageIndex - 1].imageBase64 ? imageArray[imageIndex - 1].imageBase64 : imageArray[0].imageBase64}` : '',
                      transmittalNo
                    )
                  }
                />
              </div>

              <Grid item>
                {/* <IconButton aria-label="close" className="btn-cancel-custom" onClick={handleClose}>
                  <ClearSharpIcon fontSize="small" />
                </IconButton> */}
                <div className={classes.iconContainer}>
                  <CloseIcon onClick={handleClose} className={classes.closeIcon} />
                </div>
              </Grid>
            </Grid>

            <Grid container style={{ marginTop: '24px' }}>
              <div id="proof-image"></div>
              <img
                className={classes.imgDetails}
                src={getImageSrc()}
                alt="thumbnail"
              />
            </Grid>
            <div className={classes.paginationContainer}>
              <Pagination
                count={imageArray.length}
                defaultPage={imageIndex}
                shape="rounded"
                size="small"
                onChange={handlePagination}
              />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
    <DeleteAttachment
    setOpenDelete={setOpenDelete}
    setOpen={setOpen}
    openDelete={openDelete}
    imageArray={imageArray}
    imageIndex={imageIndex}
    transmittalId={transmittalId}
    transmittalNo={transmittalNo}
    close={close}
    />
    </>
    
  );
};
Thumbnail.propTypes = {
  setOpen: PropTypes.any,
  open: PropTypes.bool,
  img: PropTypes.any,
  sourceURL: PropTypes.any,
  transmittalNo: PropTypes.any,
  transmittalId: PropTypes.any,
  toDataURL: PropTypes.any,
  handlePagination: PropTypes.func,
  imageArray: PropTypes.array,
  imageIndex: PropTypes.number,
};
export default Thumbnail;
